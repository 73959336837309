<template>
  <div id="subpage-content">
    <InformsDrawer />
    <div id="rcol">
      <v-card id="rcol-incard">
        <h1 id="naming">{{ $t("message.OurPres") }}</h1>
        <v-col v-for="item in items" :key="item.id" :cols="item.id">
          <div>
            <v-card id="grey">
              <div id="abt-bg">
                <div><v-icon x-large>mdi-format-quote-open</v-icon></div>
                <div id="left-pad">{{ getItemText(item) }}</div>
              </div>
              <div id="recom">
                <div id="file">
                  <v-icon x-large>mdi-file</v-icon>
                  <div id="file-sub">
                    <a
                      v-on:click="downloadFile(item)"
                      id="link"
                      v-bind:title="getItemName(item)"
                      >{{ getItemName(item) }}</a
                    >
                    <a>{{ $t("message.Size") + getItemSize(item) }} </a>
                  </div>
                </div>
              </div>
            </v-card>
            <div id="file">
              <div id="file-sub-name">
                <!-- <a id="bold">{{ $t("message.Bistrov") }}</a> -->
                <a>{{ getItemDescription(item) }}</a>
              </div>
            </div>
          </div>
        </v-col>
      </v-card>
      <div v-if="!artLoading" class="loader" style="--b: 10px;--c:#00569c;width:80px;--n:15;--g:7deg"></div>
    </div>
  </div>
</template>

<script>
const axios = require("axios").default;
import { mapState } from "vuex";
import InformsDrawer from "../../../views/Navigations/Informs/InformsDrawer";
export default {
  components: {
    InformsDrawer,
  },
  data: () => ({
    items: [],
  }),
  created() {
    window.addEventListener("beforeunload", this.handler());
  },
  async mounted() {
    await this.getArticleblockNoFile();
    // await this.getArticleblock();
  },
  computed: mapState({
    artLoading: (state) => state.articleblocks.artLoading,
    
  }),
  methods: {
    async handler() {
      await this.$store.dispatch("getAllArticleblocksNoFile");
      this.items = await this.$store.getters.getArticleblocksNoFile.filter(
        (item) => item.ispresentation
      );
      this.items = this.items.sort((a,b) => a.sortparam - b.sortparam)
    },

    async getArticleblockNoFile() {
      // await this.$store.dispatch("getAllArticleblocks");
      this.items = await this.$store.getters.getArticleblocksNoFile.filter(
        (item) => item.isarticle
      );
      this.items = this.items.sort((a, b) => a.sortparam - b.sortparam);
    },

    async getArticleblock() {
      // await this.$store.dispatch("getAllArticleblocks");
      this.items = await this.$store.getters.getArticleblocks.filter(
        (item) => item.ispresentation
      );
      this.items = this.items.sort((a,b) => a.sortparam - b.sortparam)
    },
    getItemName(item) {
      return item.name + ".pdf";
    },
    async getFileUrl(item) {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig
      let response = await axios.get(this.config + "/GetArticleblock?id=" + item.id);
      let respFile = response.data.file;
      const linkSource = `data:application/octet-stream;base64,${respFile}`;
      const downloadLink = document.createElement("a");
      const fileName = this.getItemName(item);
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      return "";
    },
    downloadFile(item) {
      this.getFileUrl(item);
    },
    getItemSize(item) {
      return item.size;
    },
    getItemText(item) {
      if (this.$i18n.locale === "ru") {
        return item.text2;
      } else if (this.$i18n.locale === "gb") {
        return item.text1;
      } else if (this.$i18n.locale === "de") {
        return item.text3;
      }
    },
    getItemDescription(item) {
      if (this.$i18n.locale === "ru") {
        return item.description2;
      } else if (this.$i18n.locale === "gb") {
        return item.description1;
      } else if (this.$i18n.locale === "de") {
        return item.description3;
      }
    },
  },
};
</script>
<style>
#grey {
  background-color: rgb(235, 234, 243);
  margin-top: 20px;
  font: 13px/20px Ubuntu, Arial, sans-serif;
}
#abt-bg {
  display: flex;
  padding-top: 20px;
}
#left-pad {
  padding-left: 15px;
}
#file {
  margin: 10px;
  display: flex;
  font: 13px/20px Ubuntu, Arial, sans-serif;
}
#file-sub {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
}
#file-sub-name {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  background-color: rgb(255, 255, 255);
}
#link {
  color: #5490e6;
  cursor: hand;
}
#link:hover {
  text-decoration-line: underline;
}
</style>